import { default as indexRB1KviEwVnMeta } from "/web/pages/index.vue?macro=true";
import { default as login3Dbg61UC5WMeta } from "/web/pages/user/login.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: indexRB1KviEwVnMeta || {},
    component: () => import("/web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "user-login",
    path: "/user/login",
    component: () => import("/web/pages/user/login.vue").then(m => m.default || m)
  }
]